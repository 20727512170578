@font-face {
  font-family: Mukta;
  src: url(../assets/fonts/Mukta-Regular.ttf);
}

@font-face {
  font-family: MuktaLight;
  src: url(../assets/fonts/Mukta-Light.ttf);
}

@font-face {
  font-family: Rubik;
  src: url(../assets/fonts/Rubik-Regular.ttf);
}

@font-face {
  font-family: RubikLight;
  src: url(../assets/fonts/Rubik-Light.ttf);
}

@font-face {
  font-family: FiraSans;
  src: url(../assets/fonts/FiraSans-Regular.ttf);
}

@font-face {
  font-family: Dosis;
  src: url(../assets/fonts/Dosis-Medium.ttf);
}

@font-face {
  font-family: Signika;
  src: url(../assets/fonts/SignikaNegative-Regular.ttf);
}

@font-face {
  font-family: Overpass;
  src: url(../assets/fonts/Overpass-Regular.ttf);
}

@font-face {
  font-family: MontserratAlternates;
  src: url(../assets/fonts/MontserratAlternates-Regular.ttf);
}

@font-face {
  font-family: Poppins;
  src: url(../assets/fonts/Poppins-Regular.ttf);
}

@font-face {
  font-family: Lato;
  src: url(../assets/fonts/Lato-Regular.ttf);
}

@font-face {
  font-family: Inter;
  src: url(../assets/fonts/Inter-Regular.ttf);
}

body {
  font-family: Poppins !important;
  letter-spacing: 0.04em !important;
  color: #262b40 !important;
}

label {
  font-weight: 400;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: Inter !important;
  font-weight: 400;
  letter-spacing: 0.05em !important;
}

.pointer {
  cursor: pointer;
}

.text-custom-pink-red {
  color: #63e6be !important;
}

.text-custom-pink-red {
  color: #e53265 !important;
}

.table-custom-pink-red {
  background-color: #e53265 !important;
  color: #fff;
}

.table-custom-pink-bs-blue {
  background-color: #0e3352 !important;
  color: #fff;
}

.modal-content {
  border-radius: 1.3rem !important;
}

.swal2-popup {
  border-radius: 1.3rem !important;
}

.imageUploadedPreview {
  border-radius: 1em;
  object-fit: cover;
  height: 14em;
  width: 98%;
}

.react-date-picker__wrapper {
  border: 0px !important;
}

// .table th {
//     padding: 0.7em !important;
// }

.react-bs-table-sizePerPage-dropdown > button {
  color: #262b40 !important;
  background-color: #fff !important;
  border-color: #fff !important;
}

.react-bs-table-sizePerPage-dropdown > button::before {
  content: "Registros: ";
}

.loadingParent {
  position: absolute;
  height: 100vh;
  z-index: 10000;
  width: 100%;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: -12em;
}

.pagesCustomParent {
  position: relative;
}

.w-10 {
  width: 10% !important;
}

.w-20 {
  width: 20% !important;
}

.w-30 {
  width: 30% !important;
}

.w-40 {
  width: 40% !important;
}

.w-50 {
  width: 50% !important;
}

.w-60 {
  width: 60% !important;
}

.w-70 {
  width: 70% !important;
}

.w-80 {
  width: 80% !important;
}

.w-90 {
  width: 90% !important;
}

.ml-3 {
  margin-left: 3em;
}

.ml-4 {
  margin-left: 4em;
}

.parrafo-aviso {
  font-size: 0.7rem;
}

.factura-productos-lista {
  border: 1px solid #e0e7f1;
  border-radius: 1em;
  padding: 24px 10px;
  background-color: #fff;
}

.factura-nuevo-cliente-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border: 2px solid #e0e7f1;
  border-radius: 1em;
  padding: 24px 10px;
  background-color: #f5f8fb;
}

.factura-nuevo-cliente-handler {
  font-weight: 800;
  font-size: 1.4em;
  letter-spacing: 1.75px;
  cursor: pointer;
}

.factura-totales-container {
  border: 1px solid #e0e7f1;
  border-radius: 1em;
  background-color: #fff;
}

.factura-totales {
  display: flex;
  justify-content: space-around;
  text-align: center;
}

.factura-totales > span {
  flex: 0 0 50%;
}

button {
  font-weight: 600 !important;
  letter-spacing: 1px !important;
}

.factura-cliente-propiedad-titulo {
  font-weight: 600;
  font-size: 1.6em;
  letter-spacing: 0.02em;
}

.factura-cliente-propiedad-info {
  font-weight: 400;
  font-size: 1.2em;
  letter-spacing: 0.01em;
}

.rbt-close {
  border-radius: 8px;
  border: 0;
  height: 2em;
  width: 2em;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  margin-right: 2px;
}

.sidebar-text {
  // font-weight: 900;
  // letter-spacing: 0.85px;
  font-family: Poppins !important;
}

.imagenes-todo-crud {
  object-fit: cover;
  height: auto;
  max-height: 12em;
  border-radius: 0.75em;
  width: 100%;
}
//datalist with better view
.datalist {
  position: absolute;
  z-index: 1000;
  width: 100%;
  margin-top: 2px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  background-color: #fff;
  overflow: auto;
  max-height: 200px;
}

//add * to label of required fields
label.required:after {
  content: " *";
  color: red;
}
.modal-90w {
  max-width: 90%;
}

.modal-100w {
  max-width: 98%;
  margin: 0 auto;
}
.video-container {
  position: relative;
  //padding-bottom: 56.25%; /* Establece el aspect ratio (16:9) */
  height: 100vh;
  overflow: hidden;
}

.video-container video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.guide-lines {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.vertical-line {
  position: absolute;
  left: 50%;
  top: 0;
  bottom: 0;
  width: 2px; /* Ancho de la línea vertical */
  background-color: red; /* Color de la línea vertical */
  transform: translateX(-50%);
}

.horizontal-line {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  height: 2px; /* Altura de la línea horizontal */
  background-color: red; /* Color de la línea horizontal */
  transform: translateY(-50%);
}
.form-control{
  color: #16181d !important;
  font-weight: 400;
  letter-spacing: 1px;
}